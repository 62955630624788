import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Nav from '../components/navigation'
import Footer from '../components/footer'


class BlogPostTemplate extends React.Component {

  render() {
    const host = 'https://www.reddigitalchina.com'
    const post = get(this.props, 'data.contentfulBlogPost')
    const servicesNav = get(this.props,'data.allContentfulOurServices.edges')
    const heroImage = {
      background:`linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${post.heroImage.file.url}")`,
      backgroundSize:'cover'
    }

    return (
      <div>
        <Helmet>
          <html lang="en"/>
          <title>{post.title} | Red Digital China</title>
          <meta name="description" content={post.description.childMarkdownRemark.excerpt}/>
          <meta property="og:url" content={`${host}/blog/${post.slug}`}/>
          <meta property="og:type" content="article"/>
          <meta property="og:local" content="en_GB"/>
          <meta property="og:title" content={post.title}/>
          <meta property="og:description" content={post.description.childMarkdownRemark.excerpt}/>
          <meta property="og:site_name" content="Red Digital China: Chinese digital agency"/>
          <meta property="og:image" content={post.heroImage.file.url}/>
          <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="79580515-4c86-4e39-aa84-3d661091d2e0" type="text/javascript" async></script>
        </Helmet>
        <section className="hero is-medium" style={heroImage}>
          <div className="hero-head">
            <Nav servicesNav={servicesNav}/>
          </div>
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title has-text-white is-capitalized is-size-1 is-size-3-mobile">{post.title}</h1>
              <p className="has-text-white is-capitalized">{post.publishDate}</p>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-8">
                <p className="is-size-4 is-size-4-mobile">
                  {post.description.childMarkdownRemark.rawMarkdownBody}
                </p>
                <hr className="title-underline"/>
                <div className="content is-large is-size-5-mobile"
                    dangerouslySetInnerHTML={{
                      __html: post.body.childMarkdownRemark.html,
                    }}
                />
              </div>
            </div>
          </div>        
        </section>
        <Footer/>
      </div>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          title
          slug
        }
      }
    }  
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      keyWords
      slug
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        file{
            url
        }
      }
      description{
        childMarkdownRemark {
          rawMarkdownBody
          excerpt
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`